import React from "react"
import Layout from "../templates/main/layout"
import { Link } from "gatsby"
import SEO from "../components/seo"
import { useStaticRestData } from "../hooks/data"

const AgreementPage = () => {
  const { allStaticBlock } = useStaticRestData()
  const agreementItem = allStaticBlock.nodes.filter(item => item.alternative_id === 5080)[0]
  return (
    <Layout>
      <SEO title="Согласие на обработку персональных данных" />
      <Link to="/">Вернуться на главную</Link>
      <div dangerouslySetInnerHTML={{ __html: agreementItem.detailText }} />
    </Layout>
  )
}

export default AgreementPage